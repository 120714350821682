.App {
  text-align: center;
  font-family: sans-serif;
  background: #303030;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */
